import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import NavBar from "./components/NavBar/NavBar";
import Carousel from "./components/Carousel/Carousel";
import Card from "./components/Card/Card";
import Contact from "./components/Contact/Contact";

function App() {
  return (
    <>
      <div className="app" id="app"></div>
      <NavBar />
      <div className="welcomeMsg">
        <h1 id="welcome-txt">KlimStan</h1>
        <h3 id="welcome-under">Klimatyzacja i pompy ciepla</h3>
      </div>
      <Card />
      <Carousel />
      <h1 className="title">KlimStan</h1>
      <div className="line-wrapper">
        <div className="line"></div>
      </div>
      <div className="about">
        <div className="left" id="textStyle">
          <div className="pictureFeatureLeft"></div>
          <p>
            <h1 id="titleStyleFeat">Doświadczenie</h1>
            Jesteśmy młodą, dynamiczną firmą składającą się z doświadczonych
            ludzi, którzy są gotowi dostarczyć Ci najlepsze rozwiązania w
            zakresie klimatyzacji. Naszym głównym celem jest zapewnienie Ci
            komfortu termicznego w Twoim domu, a nasze zamiłowanie do
            klimatyzacji sprawia, że robimy to z pasją i zadowoleniem. Twoja
            satysfakcja i wygoda są dla nas najważniejsze, dlatego zawsze
            staramy się działać w sposób, który będzie dla Ciebie optymalny, a
            nasza ofertę dopasowujemy do Twoich potrzeb.
          </p>
        </div>
        <div className="line-wrapper">
          <div className="line"></div>
        </div>
        <div className="right" id="textStyle">
          <p>
            <h1 id="titleStyleFeat">Uczciwość i transparentność</h1>
            Wierzymy, że dzięki naszemu zaangażowaniu i wiedzy na temat
            klimatyzacji, możemy zaoferować Ci najlepsze rozwiązania, które będą
            dostosowane do Twojego stylu życia i budżetu. Naszym priorytetem
            jest uczciwość i transparentność w naszych działaniach, dlatego
            zawsze wyceniamy nasze usługi w sposób uczciwy i konkurencyjny.
            Pragniemy,abyś czuł się pewnie z naszą firmą, dlatego zawsze
            dokładnie informujemy Cię o wszystkich wadach i zaletach wybranego
            rozwiązania, abyś mógł podjąć najlepszą decyzję. Dziękujemy za
            zainteresowanie naszą ofertą i zapraszamy do kontaktu!
          </p>
          <div className="pictureFeatureRight"></div>
        </div>
      </div>
      <Contact />
    </>
  );
}

export default App;
