import NavBar from "../NavBar/NavBar";
import Card from "react-bootstrap/Card";
import montaz from "../../images/montaz.jpg";

function Porady() {
  return (
    <>
      <NavBar />
      <div className="card-wrapper">
        <span>
          <Card style={{ width: "25rem" }}>
            <Card.Img
              variant="top"
              src={montaz}
              style={{ maxHeight: "320px" }}
            />
            <Card.Body>
              <Card.Title style={{ textAlign: "center" }}></Card.Title>
              <Card.Text>
              Tutaj bedzie jakas porada, dla klienta tdo przeczytania.
              </Card.Text>
                <a href="/"> czytaj dalej...</a>
            </Card.Body>
          </Card>
        </span>
      </div>
    </>
  );
}

export default Porady;
