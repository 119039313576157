import { useEffect, useState } from "react";

import "./NavBar.css";
import logo from "../../images/Logo.png";

function NavBar() {
  const [scrolled, setScrolled] = useState(false);

  const handleScroll = () => {
    if (window.scrollY > 0) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <nav className={`navbar ${scrolled ? "scrolled" : ""}`}>
      <a href="/#app">
        {" "}
        <img
          className={`logo ${scrolled ? "scrolled" : ""}`}
          src={logo}
          alt="logo"
        />
      </a>

      <ul>
        {/* <li>
          <a className={`link ${scrolled ? "scrolled" : ""}`} href="/porady">
            Porady
          </a>
        </li> */}
        {/* <li>
          <a href="/">Montaz</a>
        </li> */}
        <li>
          <a className={`link ${scrolled ? "scrolled" : ""}`} href="#contact">
            Kontakt
          </a>
        </li>
      </ul>
    </nav>
  );
}

export default NavBar;
