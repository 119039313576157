import Carousel from "react-bootstrap/Carousel";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Carousel.css";
function CarouselBar() {
  return (
    <div className="carousel-bar">
      <Carousel>
        <Carousel.Item>
          <p className="carousel-text">
            "Byłem całkowicie zachwycony usługą, którą otrzymałem. Pracownicy
            byli bardzo profesjonalni i uprzejmi, a instalacja klimatyzatora
            przebiegła bardzo sprawnie. Teraz moje mieszkanie jest idealnie
            schłodzone i nie mogę się doczekać, aby skorzystać z Waszych usług
            ponownie!"
          </p>
        </Carousel.Item>

        <Carousel.Item>
          <p className="carousel-text">
            "Naprawa mojego klimatyzatora przez firmę KlimStan była zdecydowanie
            najlepszym doświadczeniem, jakie miałem z serwisem klimatyzacyjnym.
            Technicy byli bardzo kompetentni i szybko rozwiązali problem, dzięki
            czemu mój klimatyzator działa lepiej niż kiedykolwiek wcześniej.
            Zdecydowanie polecam każdemu, kto potrzebuje profesjonalnej pomocy w
            zakresie klimatyzacji."
          </p>
        </Carousel.Item>

        <Carousel.Item>
          <p className="carousel-text">
            "Dzięki firmie KlimStan udało mi się wreszcie zainstalować
            klimatyzację w moim domu, czego od dawna pragnąłem. Pracownicy byli
            bardzo pomocni i doradzili mi najlepsze rozwiązania, aby dopasować
            klimatyzator do moich potrzeb. Teraz moja rodzina i ja możemy
            cieszyć się chłodnym powietrzem w upalne dni, a to wszystko dzięki
            Wam! Na pewno skorzystamy z Waszych usług ponownie w przyszłości."
          </p>
        </Carousel.Item>
      </Carousel>
    </div>
  );
}

export default CarouselBar;
