import "./Contact.css";
// import { useState } from "react";
// import { Modal } from "antd";

function MyContact() {
  // const [isModalOpen, setIsModalOpen] = useState(false);

  // const showPrice = () => {
  //   setIsModalOpen(true);
  // };

  // const handleOk = () => {
  //   setIsModalOpen(false);
  // };

  // const handleCancel = () => {
  //   setIsModalOpen(false);
  // };
  return (
    <>
      <div className="contact-wrapper" id="contact">
        <div className="contact-details">
          <h1>Skontaktuj sie z nami!</h1>
          <p className="title-text">KlimStan</p>
          <p>Bobrowniki 26</p>
          <p>73-120 Chociwel</p>
          <a className="phone" href="tel:+48459245145">
            Tel: +48 459 245 145
          </a>
          {/* <p className="email-link"> */}
          <a className="email" href="mailto:klimstan@mailplus.pl">
            {" "}
            <span>✉️ </span>klimstan@mailplus.pl
          </a>
          {/* </p> */}
        </div>
        {/* <div className="price-box" onClick={showPrice}>
          <h4>Cennik</h4>
        </div> */}
        {/* <Modal
          title="Cennik"
          open={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
          footer={null}
        >
          <p> cennik </p>
          <p> cennik </p>
          <p> cennik </p>
          <p> cennik </p>
          <p> cennik </p>
          <p> cennik </p>
        </Modal> */}
      </div>
    </>
  );
}

export default MyContact;
